<template>
    <div class="uk-width-1-1 uk-height-1-1 uk-flex uk-flex-middle uk-flex-center uk-flex-column">
        <img 
            :src="`${images}/empty-data.svg`" 
            alt="Empty data image"
            loading="lazy" 
        />
        <div class="uk-width-1-1 uk-margin-top">
            <p v-if="message1" class="uk-margin-remove uk-text-center myrobin-empty-data-text">{{ message1 }}</p>
            <p v-if="message2" class="uk-margin-remove uk-text-center myrobin-empty-data-text">{{ message2 }}</p>
            <p v-if="message3" class="uk-margin-remove uk-text-center myrobin-empty-data-text">{{ message3 }}</p>
        </div>
    </div>
</template>

<script>
import { PREFIX_IMAGE } from '@/utils/constant';

export default {
    name: 'AdditionalQuestionsEmptyData',
    props: {
        message1: {
            type: String,
            required: false,
            default: 'No categories have been created yet.'
        },
        message2: {
            type: String,
            required: false,
            default: "Please create a category first by clicking the 'Create New Category' button."
        },
        message3: {
            type: String,
            required: false,
            default: ''
        }
    },
    data() {
        return {
            images: PREFIX_IMAGE,
        };
    }
};
</script>

<style scoped>
.myrobin-empty-data-text {
    font-size: 20px;
    font-style: italic;
    color: #A7A7A7;
    font-weight: 400;
}
</style>
